import { mapGetters, mapActions } from 'vuex'
import { ADD_TOAST_MESSAGE } from 'vuex-toast'
import StandardCardChannel from '@/mixins/standardCardChannel'
import {
  SvgLoader,
  ChannelPeoplechat,
  Loading,
  ChannelFacebookPages,
  ChannelStandard,
  ChannelWhatsapp
} from '@/components'

export default {
  components: {
    SvgLoader,
    ChannelPeoplechat,
    Loading,
    ChannelFacebookPages,
    ChannelStandard,
    ChannelWhatsapp
  },
  mixins: [
    StandardCardChannel
  ],
  data () {
    return {
      isLoading: false,
      disabled: false,
      switchBool: false
    }
  },
  mounted () {
    this.getChannels()
  },
  computed: {
    ...mapGetters([
      'getterSelectedBot',
      'getterChannels'
    ]),
    channelsList () {
      const list = []

      for (let i = 0; i < Object.keys(this.getterChannels).length; i++) {
        const channelName = Object.keys(this.getterChannels)[i]
        if (channelName !== 'botId') {
          const newChannel = {
            ...this.getterChannels[channelName],
            ...this.editSpecifChannel(channelName),
            type: channelName
          }

          if ((channelName === 'facebook' || channelName === 'workplace' || channelName === 'generic')) {
            if (!this.getterChannels[channelName].pageAccessToken || !this.getterChannels[channelName].webhook) {
              this.generateToken('webhook', newChannel)
            }
          }

          newChannel.connected = this.checkChannelConnected(newChannel)

          list.push(newChannel)
        }
      }

      return list
    }
  },
  methods: {
    ...mapActions([
      'actionGetAllChannels'
    ]),
    ...mapActions({
      addToast: ADD_TOAST_MESSAGE
    }),
    checkChannelConnected (channelData) {
      if (channelData.enabled) {
        return true
      }
      if (channelData.controllAccess && channelData.controllAccess.length > 0) {
        if (channelData.type !== 'facebook' && channelData.type !== 'workplace' && channelData.type !== 'generic') {
          return true
        }
        if (channelData.type === 'facebook') {
          return channelData.pageAccessToken && channelData.pageAccessToken.length > 0
        }
        if (channelData.type === 'workplace') {
          return channelData.accessToken && channelData.accessToken.length > 0
        }
        if (channelData.type === 'generic') {
          return channelData.webhook && channelData.webhook.length > 0
        }
        return false
      }

      return false
    },
    editSpecifChannel (channelName) {
      const typesAllowed = ['facebook', 'generic', 'workplace', 'peoplechat', 'whatsapp']

      const data = {
        channelName,
        channelLogo: `/img/icon-${channelName}.svg`,
        channelLogoBackground: '',
        actionName: '',
        isAllowed: typesAllowed.filter((type) => type === channelName).length > 0
      }

      if (this.getterChannels[channelName]) {
        switch (channelName) {
          case 'msteams':
            data.channelName = 'Microsoft teams'
            data.channelLogo = '/img/icon-ms-teams.svg'
            break
          case 'whatsapp':
            data.channelName = 'whatsapp'
            data.channelLogo = '/img/icon-whatsapp.svg'
            break
          case 'generic':
            data.channelName = 'Chat API'
            data.channelLogo = '/img/icon-channels.svg'
            break
          case 'peoplechat':
            data.channelName = 'Peoplechat'
            data.channelLogo = !this.getterSelectedBot.logo ? '/img/icon-bot.svg' : this.getterSelectedBot.logo
            data.channelLogoBackground = this.getterSelectedBot.botColor
            break
          case 'facebookPages':
          case 'facebook':
            data.channelName = this.$t('channels.facebookPages.title')
            data.channelLogo = '/img/icon-facebook.svg'
            break
          case 'twillio':
            data.channelName = 'Twillio'
            data.channelLogo = '/img/icon-twillio.svg'
            break
          case 'sms':
            data.channelName = 'sms'
            data.channelLogo = '/img/icon-email.svg'
            break
          case 'googleAssistant':
            data.channelName = 'Google Assistant'
            data.channelLogo = '/img/icon-googleAssistant.png'
            break
          case 'slack':
            data.channelName = 'Slack'
            data.channelLogo = '/img/icon-slack.svg'
            break
          case 'alexa':
            data.channelName = 'Alexa'
            data.channelLogo = '/img/icon-alexa.png'
            break
          case 'telegram':
            data.channelName = 'Telegram'
            data.channelLogo = '/img/icon-telegram.svg'
            break
          case 'workplace':
            data.channelName = 'Workplace'
            data.channelLogo = '/img/icon-workplace.svg'
            break
          case 'cortana':
            data.channelName = 'Cortana'
            data.channelLogo = '/img/icon-cortana.svg'
            break
          default:
            data.channelName = channelName
            data.channelLogo = '/img/icon-channels.svg'
            break
        }

        data.actionName = !this.getterChannels[channelName].enabled ? this.$t('channels.allowAccess', [data.channelName]) : this.$t('channels.disconnectAccount')
      }

      return data
    },
    getChannels () {
      this.isLoading = true

      this.actionGetAllChannels({ idBot: this.getterSelectedBot.idBot })
        .then(() => {
          this.isLoading = false
        })
        .catch(() => {
          this.addToast({
            text: this.$t('channels.toast.errorFetchingChannel'),
            type: 'danger'
          })

          this.isLoading = false
        })
    }
  }
}
