var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "page-channels internal-page"
  }, [_c('section', {
    staticClass: "internal-generic-header"
  }, [_c('div', {
    staticClass: "search-header"
  }, [_c('div', {
    staticClass: "container-title",
    on: {
      "click": function click(e) {
        e.stopPropagation();
      }
    }
  }, [_c('h6', [_vm._v(_vm._s(_vm.$t('channels.channelsIntegrations')))])]), _c('div', {
    staticClass: "container-search",
    on: {
      "click": function click(e) {
        e.stopPropagation();
      }
    }
  }, [_c('button', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip.auto",
      value: {
        content: _vm.$t('channels.tooltip.channelsScreen'),
        offset: 10
      },
      expression: "{ content: $t('channels.tooltip.channelsScreen'), offset: 10 }",
      modifiers: {
        "auto": true
      }
    }],
    staticClass: "btn-question"
  }, [_c('svgLoader', {
    attrs: {
      "src": "/img/icon-question.svg",
      "color": "#000"
    }
  })], 1)])])]), _vm.isLoading ? _c('loading') : _vm._e(), !_vm.isLoading && Object.keys(_vm.getterChannels).length !== 0 && _vm.getterChannels.constructor === Object ? _c('ul', {
    staticClass: "channels-list ml-8"
  }, _vm._l(_vm.channelsList, function (channel, index) {
    return _c('li', {
      key: "".concat(index, "-").concat(channel.type),
      staticClass: "card",
      class: "card-".concat(channel.type)
    }, [channel.type === 'peoplechat' ? _c('channel-peoplechat', {
      attrs: {
        "channel": channel
      }
    }) : channel.type === 'facebookPages' ? _c('channel-facebook-pages', {
      attrs: {
        "channel": channel
      }
    }) : channel.type === 'whatsapp' ? _c('channel-whatsapp', {
      attrs: {
        "channel": channel
      }
    }) : _c('channel-standard', {
      staticClass: "standard",
      attrs: {
        "channel": channel
      }
    })], 1);
  }), 0) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }